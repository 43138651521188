import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'

import styled from 'styled-components'

const GoodiesContainer = styled.div`
  display: block;
  max-height: 600px;
  overflow: hidden;
  position: relative;
  align-self: center;

  p {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 60px;
    color: ${({ theme }) => theme.colors.white};
    text-transform: uppercase;

    .collection {
      font-family: retiro-std-24pt, sans-serif;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
      font-size: 60px;
      margin-top: 30px;

      @media only screen and (max-width: 740px) {
        font-size: 24px;
      }
    }
  }
`

const Goodies = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      goodies: file(relativePath: { eq: "goodies.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <GoodiesContainer>
      <Link to="/news">
        <p>
          <span className="collection">{t('goodies.description')}</span>
        </p>
        <Image
          fluid={data?.goodies?.childImageSharp?.fluid}
          alt={t('goodies.description')}
          title={t('goodies.description')}
        />
      </Link>
    </GoodiesContainer>
  )
}

export default Goodies
