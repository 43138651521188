import React from 'react'

import Image from 'gatsby-image'

import { Link, useTranslation } from 'gatsby-plugin-react-i18next'

import { useWindowSize } from 'react-use'
import styled from 'styled-components'

import { Title, TitleWithBorder } from '../../styled'
import { Button } from '../../styled/button'

const CategoryContainer = styled.div`
  position: relative;

  img {
    max-width: 100%;
    filter: brightness(0.9);
    transition: all 0.3s linear;

    &:hover {
      filter: brightness(1);
    }
  }

  .title {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    color: ${({ theme }) => theme.colors.white};
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);

    button {
      margin-top: 10px;
      height: 42px;
    }
  }
`

const Category: React.FC = ({
  name,
  to,
  description,
  image,
  smallImage,
}: any) => {
  const { width } = useWindowSize()
  const { t } = useTranslation()

  return (
    <CategoryContainer>
      <Link to={to}>
        {(width <= 740 ||
          (typeof navigator !== 'undefined' &&
            /Android|webOS|iPhone|iPod|BlackBerry/i.test(
              navigator?.userAgent
            ))) &&
        smallImage ? (
          <Image
            fluid={smallImage?.childImageSharp?.fluid}
            alt={description}
            title={name}
          />
        ) : (
          <>
            <Image
              className={smallImage ? 'large-only' : ''}
              fluid={image?.childImageSharp?.fluid}
              alt={description}
              title={name}
            />
            {smallImage ? (
              <Image
                className="small-only"
                fluid={smallImage?.childImageSharp?.fluid}
                alt={description}
                title={name}
              />
            ) : undefined}
          </>
        )}
        <div className="title">
          <TitleWithBorder>{name}</TitleWithBorder>
          <Title>{description}</Title>
          <Button>{t('label.shopNow')}</Button>
        </div>
      </Link>
    </CategoryContainer>
  )
}

export default Category
