import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import styled from 'styled-components'

import { Container } from '../../styled'

import Category from './category'

const ShowoffContainer = styled(Container)`
  display: block;
  padding: 60px 30px;
`

const Showoff = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      sweatshirt: file(relativePath: { eq: "sweatshirt.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sweatshirt_min: file(relativePath: { eq: "sweatmin.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const showoff = {
    name: t(`type.Sweat-Shirt`),
    description: t(`Sweat-Shirt.description`),
    to: `/shop/sweat-shirt`,
    smallImage: data?.sweatshirt_min,
    image: data?.sweatshirt,
  }

  return (
    <ShowoffContainer>
      <Category {...showoff} />
    </ShowoffContainer>
  )
}

export default Showoff
