import React, { useContext, useEffect, useState } from 'react'

import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import styled from 'styled-components'

import { Bestsellers, BrandAmbassador, Intro, SEO } from '../components'
import Goodies from '../components/goodies'
import { ProductList } from '../components/product'
import JsonLd from '../components/seo/jsonld'
import ShopByCategories from '../components/shop-by-categories'
import Showoff from '../components/shop-by-categories/showoff'
import { URL } from '../config'
import StoreContext from '../context/store'
import { Container } from '../styled'

const InstaContainer = styled(Container)`
  padding: 60px 30px;
  text-align: center;
  h2 {
    text-transform: uppercase;
    font-family: quasimoda-2, quasimoda, sans-serif;
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 16px;
  }
`

const IndexPage = ({ data, path, location }: any) => {
  const { t } = useTranslation()
  const { addDiscount, store } = useContext(StoreContext)
  const [bootstrapped, setBootstrapped] = useState(false)
  const search = new URLSearchParams(location?.search || '')
  const code = search.get('discount')
  const canProcessDiscount = code && store?.checkout?.id && !bootstrapped

  useEffect(() => {
    if (canProcessDiscount) {
      addDiscount(store?.client, code)
      setBootstrapped(true)
    }
  }, [addDiscount, canProcessDiscount, code, store?.client])

  return (
    <>
      <SEO title="Keep it active... chic!" path={path} />
      <JsonLd>
        {{
          '@context': 'http://schema.org',
          '@type': 'ItemList',
          numberOfItems: '2',
          itemListElement: [
            {
              '@type': 'SiteNavigationElement',
              name: t('nav.shop.title'),
              url: `${URL}/shop`,
              position: 1,
            },
            {
              '@type': 'SiteNavigationElement',
              name: t('nav.news.title'),
              url: `${URL}/news`,
              position: 2,
            },
          ],
        }}
      </JsonLd>

      <JsonLd>
        {{
          '@context': 'https://schema.org',
          '@type': 'Organization',
          url: 'https://bymgeneration.com',
          logo: 'https://bymgeneration.com/bymparis.svg',
          image: 'https://bymgeneration.com/vodloye.png',
          name: 'BYM GENERATION',
          contactPoint: [
            {
              '@type': 'ContactPoint',
              contactType: 'Customer Service',
              email: 'bonjour@bymparis.com',
              availableLanguage: ['French', 'English'],
            },
          ],
          sameAs: [
            'https://www.facebook.com/bgbymgeneration',
            'https://www.instagram.com/bymgeneration_officiel/',
            'https://www.youtube.com/channel/UCN4Gkgy2ijWojfr1ITIi9PA',
            'https://www.pinterest.com/bymgeneration/',
          ],
        }}
      </JsonLd>
      <Intro />
      <ShopByCategories />
      <Goodies />
      <ProductList
        title={t('latestHotties.title')}
        products={data?.allShopifyProduct?.nodes}
        withGrid
      />
      <Showoff />
      <BrandAmbassador />
      <Bestsellers />
      {process.env.NODE_ENV === 'production' ? (
        <InstaContainer>
          <h2>{t('tagUsOnInstagram')}</h2>
          <div className="elfsight-app-1a99860b-9c48-4421-a536-ac92cbe6de52" />
        </InstaContainer>
      ) : undefined}
    </>
  )
}

export default IndexPage

export const query = graphql`
  query {
    file(relativePath: { eq: "kamon.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allShopifyProduct(
      sort: { order: DESC, fields: publishedAt }
      limit: 5
      filter: { title: { regex: "/^((?!DEIANIRA).)*$/s" } }
    ) {
      nodes {
        id
        title
        handle
        productType
        description
        shopifyId
        availableForSale
        options {
          name
          values
        }
        variants {
          id
          title
          price
          compareAtPrice
          availableForSale
          shopifyId
          selectedOptions {
            name
            value
          }
          image {
            originalSrc
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        images {
          originalSrc
          id
          localFile {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    shopifyCollection(handle: { eq: "ka-mon" }) {
      title
      description
      image {
        src
      }
      handle
      products {
        id
        title
        handle
        productType
        description
        shopifyId
        availableForSale
        options {
          name
          values
        }
        variants {
          id
          title
          price
          compareAtPrice
          availableForSale
          shopifyId
          selectedOptions {
            name
            value
          }
          image {
            originalSrc
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        images {
          originalSrc
          id
          localFile {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
