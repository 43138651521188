import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import styled from 'styled-components'

import { Container } from '../../styled'

import Category from './category'

const ShopByCategoriesContainer = styled(Container)`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 60px 30px;

  @media only screen and (max-width: 740px) {
    grid-template-columns: 1fr;
  }
`

const ShopByCategories: React.FC = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      bikini: file(relativePath: { eq: "bikini.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      short: file(relativePath: { eq: "short.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      top: file(relativePath: { eq: "combi.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const categories = [
    {
      name: t(`type.Sweat-Shirt`),
      description: t(`top.description`),
      to: `/shop/sweat-shirt`,
      image: data?.top,
    },
    {
      name: t(`type.Bikini`),
      description: t(`bikini.description`),
      to: `/shop/bikini`,
      image: data?.bikini,
    },
    {
      name: t(`type.Legging`),
      description: t(`legging.description`),
      to: `/shop/legging`,
      image: data?.short,
    },
  ]

  return (
    <ShopByCategoriesContainer>
      {categories?.map(category => (
        <Category key={category.name} {...category} />
      ))}
    </ShopByCategoriesContainer>
  )
}

export default ShopByCategories
